import * as React from 'react';
import Image from 'next/legacy/image';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { getImageUrl } from 'lib/util/imageUrl';
import styles from './MarketingAutomationPopup.module.css';
import { MarketingAutomationContentPane } from './MarketingAutomationContentPane';
import { Reservation } from 'models/reservation';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { getHeaderTitle, getHeaderColor } from './utils';
import config from 'config';
import { MapDesignMarketingAutomationSlideUp } from './MapDesignMarketingAutomationSlideup';

interface Props {
  campaign: MarketingAutomationCampaign;
  reservation: Reservation | null;
  bottomPosition?: number;
  onClose?: () => void;
}

export const MarketingAutomationSlideup = ({
  campaign,
  reservation,
  bottomPosition,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(
    campaign?.content?.website_popup_content?.should_show_initially || false
  );
  const webPopupContent = campaign?.content?.website_popup_content;

  const contentRef = React.useRef<HTMLDivElement>(null);

  const panelStyle = { transform: expanded ? 'translateY(0)' : 'translateY(100%)' };

  if (config.enableIAAPADemo) {
    return (
      <MapDesignMarketingAutomationSlideUp
        campaign={campaign}
        reservation={reservation}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      {!expanded && (
        <div
          className={styles['recommend__btnWrapper']}
          style={{
            bottom: bottomPosition || 0,
          }}
        >
          <Box display="flex" width="100%" justifyContent="center">
            <button
              className={styles['recommend__btn']}
              onClick={() => {
                setExpanded(true);
              }}
            >
              <div
                className={styles['recommend__header']}
                style={{ backgroundColor: getHeaderColor(webPopupContent?.content_type) }}
              >
                <div className={styles['recommend__header__content']}>
                  <div className={styles['recommend__header__ttl']}>
                    <Image
                      className={styles['recommend__header__ic_present']}
                      height={15}
                      width={15}
                      src="/static/images/ic_present.svg"
                      alt="present"
                      loader={({ src, width }) => getImageUrl(src, width)}
                    />
                    <span className={styles['recommend__header__ttl__txt']}>
                      {getHeaderTitle(webPopupContent?.content_type, t)}
                    </span>
                  </div>
                  <Image
                    className={styles['recommend__header__ic_close']}
                    style={{
                      transform: 'scale(1,-1)',
                      backgroundColor: getHeaderColor(webPopupContent?.content_type),
                    }}
                    src="/static/images/ic_down_arrow.svg"
                    alt="down allow"
                    height={15}
                    width={15}
                    loader={({ src, width }) => getImageUrl(src, width)}
                  />
                </div>
              </div>
            </button>
          </Box>
        </div>
      )}
      <div style={panelStyle} className={styles['recommend__modal']} id="recommend">
        <MarketingAutomationContentPane
          campaign={campaign}
          reservation={reservation ?? undefined}
          ref={contentRef}
          onClose={() => {
            setExpanded(false);
          }}
        />
      </div>
    </>
  );
};
