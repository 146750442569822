import * as React from 'react';

import {
  MarketingAutomationContentItem,
  MarketingAutomationCampaign,
} from 'models/marketingAutomationCampaign';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

import { getAmountAndCurrencyUnit } from './utils';
import styles from './MarketingAutomationPopup.module.css';

interface Props {
  product: MarketingAutomationContentItem;
  campaign?: MarketingAutomationCampaign;
}

export const MarketingAutomationProductListItem = ({ product, campaign }: Props) => {
  const currencyFormatter = useCurrencyFormatter();

  const formattedAmount = currencyFormatter.format(product.lowest_price_gross ?? '');
  const { priceAmount, priceUnit } = getAmountAndCurrencyUnit(formattedAmount);
  const { useApiKeyInPaths, apiKey } = React.useContext(ApiKeyContext);

  const getPageUrl = (pageName: string) => {
    return `${useApiKeyInPaths ? `/${apiKey}` : ''}${pageName}`;
  };
  const handleProductClick = () => {
    const url = getPageUrl(
      `/products/${product?.id}${campaign ? `?utm_campaign=${campaign.id}` : ''}`
    );
    window.open(url, '_blank');
  };

  return (
    <div className={styles['recommend__productList__item']} onClick={() => handleProductClick()}>
      <div className={styles['recommend__productList__item__productInfo']}>
        <div className={styles['recommend__productList__item__productInfo__img']}>
          <img src={product.image_url} alt={product.name} width={80} height={80} />
        </div>
        <div className={styles['recommend__productList__item__productInfo__props']}>
          <div className={styles['recommend__productList__item__productInfo__props__name']}>
            {product.name}
          </div>
          <div className={styles['recommend__productList__item__productInfo__props__price']}>
            <span
              className={styles['recommend__productList__item__productInfo__props__price__amount']}
            >
              {priceAmount}
            </span>
            <span
              className={styles['recommend__productList__item__productInfo__props__price__unit']}
            >
              {priceUnit}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
