import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { SurveyQuestion } from 'models/surveyTemplate';
import { getOptionalValidator, required, Validator } from 'lib/forms/validate';
import { composeValidators } from 'lib/util/composeValidators';

import styles from './SurveyForm.module.css';
import clsx from 'clsx';

interface Props {
  question: SurveyQuestion;
  onClick: () => void;
}

export const SurveyQuestionNpsRating = ({ question, onClick }: Props) => {
  const { t } = useTranslation();

  const validators: Validator[] = [];
  if (question.required) {
    validators.push(required);
  }
  if (question.format) {
    validators.push(getOptionalValidator(question.format));
  }
  const validate = validators.length > 0 ? composeValidators(...validators) : undefined;

  return (
    <div className={styles['post-form__box']} style={{ padding: '8px', marginBottom: '8px' }}>
      <div className={styles['post-form__box__ttl']}>
        <span>
          {question.question_text} {question.required && '*'}
        </span>
      </div>
      <div className={styles['post-form__box__body']} style={{ marginTop: '8px' }}>
        <div className={clsx(styles['nps__text'], styles['sp'])}>
          <p>{t('I would not recommend it')}</p>
        </div>
        <div className={clsx(styles['post-form__box__body__review'], styles['nps'])}>
          {[...Array(11)].map((_, index) => (
            <Field<string>
              key={index}
              name={`answers.${question.key}`}
              type="radio"
              value={index.toString()}
            >
              {({ input }) => (
                <label onClick={onClick}>
                  <input {...input} />
                  <div>{index}</div>
                </label>
              )}
            </Field>
          ))}
        </div>
        <div className={styles['nps__text']}>
          <p className={styles['nps__text__left']}>{t('I would not recommend it')}</p>
          <p className={styles['nps__text__right']}>{t('I would recommend it')}</p>
        </div>
        {validate && (
          <Field<string> name={`answers.${question.key}`} validate={validate}>
            {(props) =>
              props.meta.touched && props.meta.error ? (
                <p className={styles['post-form__err']}>{t(props.meta.error)}</p>
              ) : null
            }
          </Field>
        )}
      </div>
    </div>
  );
};
