import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectPopularProductSummaries } from 'lib/util/privateMarketplace';

import { MarketingAutomationHorizontalScrollProductItem } from './MarketingAutomationHorizontalScrollProductItem';

import styles from './MarketingAutomationPopup.module.css';

export const MarketingAutomationHorizontalScrollPopularProduct = () => {
  const products = useSelector(selectPopularProductSummaries);

  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (containerRef.current) {
      const totalWidth = Array.from(containerRef.current.children).reduce(
        (width, child) => width + (child as HTMLElement).offsetWidth + 16, // 16pxはgapの値
        0
      );
      containerRef.current.style.width = `${totalWidth}px`;
    }
  }, [products]);

  return (
    <div className={styles['horizontalScroll__body__inner']}>
      <div className={styles['horizontalScroll__body__inner__scroll']} ref={containerRef}>
        {products.map((product, index) => (
          <MarketingAutomationHorizontalScrollProductItem
            key={product.id}
            product={product}
            ranking={index + 1}
          />
        ))}
      </div>
    </div>
  );
};
