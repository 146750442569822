import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { SurveyQuestion } from 'models/surveyTemplate';
import { ReviewRating } from 'models/review';
import { getReviewRatingDisplayProps } from 'components/ReviewContainer/ReviewFormValues';
import { getOptionalValidator, required, Validator } from 'lib/forms/validate';
import { composeValidators } from 'lib/util/composeValidators';

import styles from './SurveyForm.module.css';

interface Props {
  question: SurveyQuestion;
}

export const SurveyQuestionRating = ({ question }: Props) => {
  const { t } = useTranslation();
  const reviewRatingsValues: ReviewRating[] = [
    'REVIEW_RATING_1',
    'REVIEW_RATING_2',
    'REVIEW_RATING_3',
    'REVIEW_RATING_4',
    'REVIEW_RATING_5',
  ];
  const reviewRatings = reviewRatingsValues.map((r: ReviewRating) =>
    getReviewRatingDisplayProps(r, t)
  );

  const validators: Validator[] = [];
  if (question.required) {
    validators.push(required);
  }
  if (question.format) {
    validators.push(getOptionalValidator(question.format));
  }
  const validate = validators.length > 0 ? composeValidators(...validators) : undefined;

  return (
    <div className={styles['post-form__box']}>
      <div className={styles['post-form__box__ttl']}>
        <span>{question.question_text}</span>
      </div>
      <div className={styles['post-form__box__body']}>
        <div className={styles['post-form__box__body__review']}>
          <div className={styles['post-form__box__body__review__stamps']}>
            {reviewRatings.map((reviewRating) => (
              <Field<ReviewRating>
                key={reviewRating.value}
                name={`answers.${question.key}`}
                type="radio"
                value={reviewRating.value}
              >
                {({ input }) => (
                  <label>
                    <input {...input} />
                    <div>
                      <img src={reviewRating.grayImg} />
                      <img src={reviewRating.colorImg} />
                    </div>
                  </label>
                )}
              </Field>
            ))}
          </div>
        </div>
        {validate && (
          <Field<ReviewRating> name={`answers.${question.key}`} validate={validate}>
            {(props) =>
              props.meta.touched && props.meta.error ? (
                <p className={styles['post-form__err']}>{t(props.meta.error)}</p>
              ) : null
            }
          </Field>
        )}
      </div>
    </div>
  );
};
