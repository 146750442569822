import * as React from 'react';

import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';

import styles from './MarketingAutomationPopup.module.css';
import { MarketingAutomationProductListItem } from './MarketingAutomationProductListItem';

interface Props {
  campaign: MarketingAutomationCampaign;
}

export const MarketingAutomationVerticalScrollProduct = ({ campaign }: Props) => {
  const webPopupContent = campaign?.content?.website_popup_content;
  return (
    <div className={styles['verticalScroll__body__inner']}>
      <div className={styles['verticalScroll__body__inner__scroll']}>
        {(webPopupContent?.products || []).map((product) => (
          <MarketingAutomationProductListItem
            key={product.id}
            product={product}
            campaign={campaign}
          />
        ))}
      </div>
    </div>
  );
};
