import * as React from 'react';
import { Reservation } from 'models/reservation';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { throttle } from 'lodash';

import {
  MarketingAutomationCampaign,
  MarketingAutomationWebsitePopupContent,
} from 'models/marketingAutomationCampaign';
import { ReduxState } from 'ducks';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';

import { MarketingAutomationInline } from './MarketingAutomationInline';
import { MarketingAutomationSlideup } from './MarketingAutomationSlideup';
import { MarketingAutomationPopup } from './MarketingAutomationPopup';
import { MarketingAutomationHorizontalScrollSlideup } from './MarketingAutomationHorizontalScrollSlideup';
import { MarketingAutomationHorizontalScrollContentPane } from './MarketingAutomationHorizontalScrollContentPane';
import { MarketingAutomationHorizontalScrollPopularProduct } from './MarketingAutomationHorizontalScrollPopularProduct';
import { MarketingAutomationHorizontalScrollInline } from './MarketingAutomationHorizontalScrollInline';
import { MarketingAutomationVerticalScrollFloatingButton } from './MarketingAutomationVerticalScrollFloatingButton';
import { MarketingAutomationVerticalScrollContentPane } from './MarketingAutomationVerticalScrollContentPane';
import { MarketingAutomationVerticalScrollPopularProduct } from './MarketingAutomationVerticalScrollPopularProduct';
import { MarketingAutomationVerticalScrollProduct } from './MarketingAutomationVerticalScrollProduct';
import { MarketingAutomationWebPushNotificationPopup } from './MarketingAutomationWebPushNotificationPopup';
import { MarketingAutomationSurvey } from './MarketingAutomationSurvey';
import { logMarketingAutomationCampaignEventWithNoSideEffects } from 'ducks/client/marketingAutomationCampaignEvents';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { CustomerContext } from 'contexts/CustomerContext';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import config from 'config';

interface Props {
  campaign: MarketingAutomationCampaign | null;
  reservation: Reservation | null;
  allowedDisplayTypes?: MarketingAutomationWebsitePopupContent['display_type'][];
  changePopupToSlideup?: boolean;
  overwriteDisplayType?: MarketingAutomationWebsitePopupContent['display_type'];
  bottomPosition?: number;
  onClose?: () => void;
}

const MarketingAutomationComponent = ({
  campaign,
  reservation,
  allowedDisplayTypes,
  changePopupToSlideup,
  overwriteDisplayType,
  bottomPosition,
  onClose,
}: Props): React.ReactElement | null => {
  const { apiKey } = React.useContext(ApiKeyContext);

  const { customer } = React.useContext(CustomerContext);
  const { currentPosition } = React.useContext(CurrentPositionContext);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);

  const debouncedLogCustomerEvent = throttle(
    ({
      apiKey,
      visitorToken,
      reservationId,
      campaign,
    }: {
      apiKey: string;
      visitorToken: string;
      reservationId: string;
      campaign: MarketingAutomationCampaign;
    }) =>
      logCustomerEventWithNoSideEffects({
        apiKey,
        eventType:
          campaign.content.type === 'PUSH_NOTIFICATION'
            ? 'push-notification-view'
            : 'website-popup-view',
        visitorToken,
        reservationId,
        metadata: JSON.stringify({
          lat: currentPosition?.lat(),
          lng: currentPosition?.lng(),
          site: 'onsite',
          marketing_automation_campaign_id: campaign.id,
        }),
        customerId: customer?.id ?? '',
      }),
    5000,
    {
      leading: true,
    }
  );

  React.useEffect(() => {
    if (!apiKey || !campaign?.id || !reservation?.id) {
      return;
    }
    logMarketingAutomationCampaignEventWithNoSideEffects({
      apiKey,
      campaignId: campaign.id,
      eventType: 'IMPRESSION',
      reservationId: reservation.id,
      customerId: customer?.id ?? '',
    });
  }, [apiKey, campaign, reservation, customer]);

  React.useEffect(() => {
    if (!apiKey || !visitorToken || !reservation?.id || !campaign) {
      return;
    }
    debouncedLogCustomerEvent({
      apiKey,
      visitorToken,
      reservationId: reservation?.id,
      campaign,
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [apiKey, visitorToken, reservation?.id, campaign]);

  if (!campaign || !reservation) {
    /* Special handling for non-reservation */

    // Reservation is null and campaign content type = 'WEBSITE_POPUP' and popup content type = 'SURVEY'
    if (
      !reservation &&
      campaign?.content?.type === 'WEBSITE_POPUP' &&
      campaign?.content?.website_popup_content?.content_type === 'SURVEY'
    ) {
      return <MarketingAutomationSurvey campaign={campaign} />;
    }
  }

  if (campaign?.content?.type === 'PUSH_NOTIFICATION') {
    return (
      <MarketingAutomationWebPushNotificationPopup
        campaign={campaign}
        reservation={reservation ?? null}
        bottomPosition={bottomPosition}
      />
    );
  }

  const webPopupContent = campaign?.content?.website_popup_content;
  let displayType = webPopupContent?.display_type || 'POPUP';

  if (overwriteDisplayType) {
    displayType = overwriteDisplayType;
  }

  if (allowedDisplayTypes && displayType && !allowedDisplayTypes.includes(displayType)) {
    return null;
  }

  if (!campaign) {
    return null;
  }

  const anchor = document.getElementById('marketing-automation-inline-anchor');
  switch (webPopupContent?.content_type) {
    case 'PRODUCT':
    case 'PRODUCT_UPGRADE':
    case 'COUPON':
    case 'ANNUAL_PASS':
    case 'MESSAGE':
      switch (displayType) {
        case 'INLINE':
          if (!anchor) {
            return null;
          }
          return ReactDOM.createPortal(
            <MarketingAutomationInline
              campaign={campaign}
              reservation={reservation ?? undefined}
            />,
            anchor as Element
          );
        case 'POPUP':
          if (changePopupToSlideup || config.enableIAAPADemo) {
            return (
              <MarketingAutomationSlideup
                campaign={campaign}
                reservation={reservation}
                bottomPosition={bottomPosition}
                onClose={onClose}
              />
            );
          }
          return (
            <MarketingAutomationPopup
              campaign={campaign}
              reservation={reservation ?? undefined}
              bottomPosition={bottomPosition}
            />
          );
        case 'FLOATING_BUTTON':
          return null;
      }
    case 'PRODUCT_LIST':
      return (
        <MarketingAutomationVerticalScrollFloatingButton campaign={campaign} onClose={onClose}>
          {(ref) => (
            <MarketingAutomationVerticalScrollContentPane ref={ref} campaign={campaign}>
              <MarketingAutomationVerticalScrollProduct campaign={campaign} />
            </MarketingAutomationVerticalScrollContentPane>
          )}
        </MarketingAutomationVerticalScrollFloatingButton>
      );
    case 'SURVEY':
      return <MarketingAutomationSurvey campaign={campaign} />;
    case 'POPULAR_PRODUCT_LIST':
      switch (displayType) {
        case 'INLINE':
          if (!anchor) {
            return null;
          }
          return ReactDOM.createPortal(
            <MarketingAutomationHorizontalScrollInline campaign={campaign}>
              <MarketingAutomationHorizontalScrollPopularProduct />
            </MarketingAutomationHorizontalScrollInline>,
            anchor as Element
          );
        case 'POPUP':
          return (
            <MarketingAutomationHorizontalScrollSlideup campaign={campaign}>
              {(ref) => (
                <MarketingAutomationHorizontalScrollContentPane ref={ref} campaign={campaign}>
                  <MarketingAutomationHorizontalScrollPopularProduct />
                </MarketingAutomationHorizontalScrollContentPane>
              )}
            </MarketingAutomationHorizontalScrollSlideup>
          );
        case 'FLOATING_BUTTON':
          return (
            <MarketingAutomationVerticalScrollFloatingButton campaign={campaign} onClose={onClose}>
              {(ref) => (
                <MarketingAutomationVerticalScrollContentPane ref={ref} campaign={campaign}>
                  <MarketingAutomationVerticalScrollPopularProduct />
                </MarketingAutomationVerticalScrollContentPane>
              )}
            </MarketingAutomationVerticalScrollFloatingButton>
          );
      }
  }

  throw new Error('Invalid marketing automation campaign content type');
};

export const MarketingAutomation = React.memo(MarketingAutomationComponent);
