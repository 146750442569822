import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { GuidancePageCoupon } from 'models/guidancePage';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { redeemCoupon } from 'ducks/client/coupons';
import { ReduxState } from 'ducks';

import ThankYouOverray from '../ThankYouOverray/ThankYouOverray';
import ConfirmCouponRedemptionModal from '../ConfirmCouponRedemptionModal/ConfirmCouponRedemptionModal';
import styles from '../MarketingAutomationPopup.module.css';

interface Props {
  buttonText: string;
  campaign: MarketingAutomationCampaign;
  reservationId: string;
  onClick: () => void;
}

const CouponPane: React.FC<Props> = ({ campaign, onClick, buttonText, reservationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);
  const [activeCoupon, setActiveCoupon] = useState<GuidancePageCoupon | null>(null);
  const [showThankYouOverlay, setShowThankYouOverlay] = useState(false);
  const [submittedTime, setSubmittedTime] = useState('');
  const submitting = useSelector((state: ReduxState) => state.coupons.loading);

  const webPopupContent = campaign?.content?.website_popup_content;

  return (
    <>
      <div className={styles['recommend__body__footer']}>
        <button
          disabled={Boolean(submittedTime)}
          className={clsx(styles['recommend__body__btn'], submittedTime && styles['submitted'])}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setActiveCoupon({
              id: webPopupContent?.coupon?.id ?? '',
              title: webPopupContent?.coupon?.name ?? '',
              description: webPopupContent?.coupon?.description ?? '',
              redeemed: false,
              thumbnail_url: '',
            });
            onClick();
          }}
        >
          <span className={styles['recommend__body__btn__txt']}>
            {Boolean(submittedTime)
              ? t('This coupon has already been used')
              : buttonText || t('MORE')}
          </span>
        </button>
      </div>
      {activeCoupon && (
        <ConfirmCouponRedemptionModal
          title={t('Are you sure you want to redeem this coupon?')}
          submitting={submitting}
          disabled={submitting}
          onConfirm={async () => {
            await dispatch(
              redeemCoupon(apiKey, {
                reservation_id: reservationId,
                coupon_id: activeCoupon.id,
                page_id: '',
              })
            );

            setShowThankYouOverlay(true);
            setActiveCoupon(null);
            const now = moment().format('YYYY/MM/DD HH:mm');
            setSubmittedTime(now);
          }}
          onCancel={() => {
            setActiveCoupon(null);
          }}
        />
      )}
      {showThankYouOverlay && <ThankYouOverray onClose={() => setShowThankYouOverlay(false)} />}
      {submittedTime && (
        <Box mt={1} mb={1}>
          <Box mt={1} display="flex" justifyContent="center">
            {t('Time of use: {{time}}', { time: submittedTime })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default CouponPane;
