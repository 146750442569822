import * as React from 'react';
import Image from 'next/legacy/image';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import config from 'config';
import { getImageUrl } from 'lib/util/imageUrl';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';

import { getHeaderTitle } from './utils';
import styles from './MarketingAutomationPopup.module.css';

interface Props {
  children: React.ReactNode;
  campaign: MarketingAutomationCampaign;
  onClose?: () => void;
}

export const MarketingAutomationVerticalScrollContentPane = React.forwardRef<HTMLDivElement, Props>(
  ({ children, campaign, onClose }, ref) => {
    const { t } = useTranslation();

    return (
      <>
        <div className={styles['recommend']}>
          <div
            className={clsx(
              styles['recommend__productList__header'],
              config.enableIAAPADemo && styles['usa']
            )}
          >
            <div className={styles['recommend__header__content']}>
              <div className={styles['recommend__header__ttl']}>
                <Image
                  className={styles['recommend__header__ic_present']}
                  height={15}
                  width={15}
                  src="/static/images/ic_present.svg"
                  alt="present"
                  loader={({ src, width }) => getImageUrl(src, width)}
                />
                <span className={styles['recommend__header__ttl__txt']}>
                  {getHeaderTitle(campaign?.content?.website_popup_content?.content_type, t)}
                </span>
              </div>
              <Image
                onClick={onClose}
                className={styles['recommend__header__ic_close']}
                height={15}
                width={15}
                src="/static/images/ic_down_arrow.svg"
                alt="down allow"
                loader={({ src, width }) => getImageUrl(src, width)}
              />
            </div>
          </div>
          <div className={clsx(styles['recommend__productList__body'])} ref={ref}>
            {children}
          </div>
        </div>
      </>
    );
  }
);
