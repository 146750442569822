import * as React from 'react';
import Image from 'next/legacy/image';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { getImageUrl } from 'lib/util/imageUrl';

import { getHeaderTitle } from './utils';
import styles from './MarketingAutomationPopup.module.css';

interface Props {
  children: (
    ref: React.Ref<HTMLDivElement>,
    props: React.HTMLProps<HTMLDivElement>
  ) => React.ReactNode;
  bottomPosition?: number;
  campaign: MarketingAutomationCampaign;
}

export const MarketingAutomationHorizontalScrollSlideup = ({
  children,
  bottomPosition,
  campaign,
}: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(
    campaign?.content?.website_popup_content?.should_show_initially || false
  );

  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !contentRef?.current?.contains(target)) {
        setExpanded(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, []);

  const panelStyle = { transform: expanded ? 'translateY(0)' : 'translateY(100%)' };

  return (
    <>
      {!expanded && (
        <div
          className={styles['recommend__btnWrapper']}
          style={{
            bottom: bottomPosition || 0,
          }}
        >
          <Box display="flex" width="100%" justifyContent="center">
            <button
              className={styles['recommend__btn']}
              onClick={() => {
                setExpanded(true);
              }}
            >
              <div className={styles['recommend__header']}>
                <div className={styles['recommend__header__content']}>
                  <div className={styles['recommend__header__ttl']}>
                    <Image
                      className={styles['recommend__header__ic_present']}
                      height={15}
                      width={15}
                      src="/static/images/ic_present.svg"
                      alt="present"
                      loader={({ src, width }) => getImageUrl(src, width)}
                    />
                    <span className={styles['recommend__header__ttl__txt']}>
                      {getHeaderTitle(campaign?.content?.website_popup_content?.content_type, t)}
                    </span>
                  </div>
                  <Image
                    className={styles['recommend__header__ic_close']}
                    style={{ transform: 'scale(1,-1)' }}
                    src="/static/images/ic_down_arrow.svg"
                    alt="down allow"
                    height={15}
                    width={15}
                    loader={({ src, width }) => getImageUrl(src, width)}
                  />
                </div>
              </div>
            </button>
          </Box>
        </div>
      )}
      <div style={panelStyle} className={styles['horizontalScroll__modal']} id="recommend">
        {children(contentRef, {})}
      </div>
    </>
  );
};
