import React from 'react';
import ReactDOM from 'react-dom';
import styles from './ThankYouOverray.module.css';
import { Wrapper } from 'components/Wrapper/Wrapper';

interface ThankYouOverrayProps {
  onClose: () => void;
}

const ThankYouOverray: React.FC<ThankYouOverrayProps> = ({ onClose }) => {
  const rootEl = typeof window !== 'undefined' ? document?.getElementById('__next') : undefined;

  return (
    <Wrapper
      wrapper={(children) =>
        rootEl ? ReactDOM.createPortal(children, rootEl as Element) : children
      }
    >
      <section className={styles.viewArea} onClick={onClose}>
        <div className={styles.imageContainer}>
          <img height={200} width={200} src="/static/images/ic_thankyou.png" />
        </div>
      </section>
    </Wrapper>
  );
};

export default ThankYouOverray;
