import { useTranslation } from 'react-i18next';

import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';

import { getHeaderTitle } from './utils';
import styles from './MarketingAutomationPopup.module.css';

interface Props {
  campaign: MarketingAutomationCampaign;
  children: React.ReactNode;
}

export const MarketingAutomationHorizontalScrollInline = ({ campaign, children }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles['horizontalScroll__inline']}>
      <div className={styles['horizontalScroll__inline__header']}>
        <div className={styles['title']}>
          <span className={styles['txt']}>
            {getHeaderTitle(campaign?.content?.website_popup_content?.content_type, t)}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};
