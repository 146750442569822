import * as React from 'react';
import { useDispatch } from 'react-redux';

import config from 'config';
import { addNotification } from 'ducks/client/notification';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useRouter } from 'next/router';

export const ReservationWebSocketConnectionReceiver = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const reservationId = router.query?.r as string;

  const { apiKey } = React.useContext(ApiKeyContext);

  React.useEffect(() => {
    if (!apiKey) {
      return;
    }

    if (!reservationId) {
      return;
    }

    try {
      const webSocketEndpointUrl = config.webSocketEndpointUrl;

      if (!webSocketEndpointUrl) {
        throw new Error('web socket endpoint URL not present in configs');
      }

      const ws = new WebSocket(
        `${webSocketEndpointUrl}?apiKey=${apiKey}&targetId=${reservationId}`
      );

      ws.onopen = () => {
        console.log('ReservationWebsocket: opened');
      };

      ws.onclose = () => {
        console.log('ReservationWebsocket: closed');
      };

      ws.onmessage = (event) => {
        if (typeof event.data === 'string') {
          const notification: any = JSON.parse(event.data);
          dispatch(addNotification(notification));
        }
      };

      return () => ws.close();
    } catch (err) {
      console.log('err', err);
    }
  }, [apiKey, dispatch, reservationId]);

  return null;
};
