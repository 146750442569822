import * as React from 'react';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SurveyQuestion } from 'models/surveyTemplate';
import { composeValidators } from 'lib/util/composeValidators';
import { getOptionalValidator, required, Validator } from 'lib/forms/validate';
import styles from './SurveyForm.module.css';

interface Props {
  question: SurveyQuestion;
  onClick: () => void;
  ref: React.Ref<HTMLDivElement>;
}
export const SurveyQuestionNpsMultiple = React.forwardRef<HTMLDivElement, Props>(
  ({ question, onClick }, ref) => {
    const { t } = useTranslation();

    const validators: Validator[] = [];
    if (question.required) {
      validators.push(required);
    }
    if (question.format) {
      validators.push(getOptionalValidator(question.format));
    }
    const validate = validators.length > 0 ? composeValidators(...validators) : undefined;

    return (
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        className={styles['post-form__box']}
        style={{ padding: '8px', marginBottom: '8px' }}
      >
        <div className={styles['post-form__box__ttl']}>
          <span>
            {question.question_text} {question.required && '*'}
          </span>
        </div>
        <div className={styles['post-form__box__body']} style={{ marginTop: '12px' }}>
          <div className={clsx(styles['post-form__box__body__checkbox'], styles['nps__checkbox'])}>
            {question.response_options?.map((option, idx) => (
              <Field<string>
                key={idx}
                name={`answers.${question.key}`}
                type="checkbox"
                value={option.key}
              >
                {({ input }) => (
                  <label
                    key={idx}
                    className={clsx(input.checked ? styles['checked'] : null)}
                    onClick={onClick}
                  >
                    <input {...input} />
                    <p>{option.text}</p>
                  </label>
                )}
              </Field>
            ))}
          </div>
          {validate && (
            <Field<string> name={`answers.${question.key}`} validate={validate}>
              {(props) =>
                props.meta.touched && props.meta.error ? (
                  <p className={styles['post-form__err']}>{t(props.meta.error)}</p>
                ) : null
              }
            </Field>
          )}
        </div>
      </div>
    );
  }
);
