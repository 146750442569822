import { Reservation } from 'models/reservation';

export const getGuestCount = (reservation: Reservation | null): Record<string, number> => {
  const guestCount: Record<string, number> = {};
  for (const guest of reservation?.guests || []) {
    const guestTypeKey = guest.guest_type_key;
    const currentGuestCount = guestCount[guestTypeKey] || 0;
    guestCount[guestTypeKey] = currentGuestCount + 1;
  }

  return guestCount;
};
