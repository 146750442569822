import { Reservation } from 'models/reservation';
import styles from './MapDesignMarketingAutomationSlideup.module.css';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { getButtonText, getContentImageUrl } from './utils';
import { getContentName } from './utils';
import { getContentDescription } from './utils';
import { getHeaderTitle } from './utils';
import { useTranslation } from 'react-i18next';
import { useWebsitePopupButtonUrl } from './useWebsitePopupButtonUrl';
import { useWebsitePopupClickLogger } from './useWebsitePopupClickLogger';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface Props {
  campaign: MarketingAutomationCampaign;
  reservation: Reservation | null;
  onClose?: () => void;
}

export const MapDesignMarketingAutomationSlideUp = ({ campaign, reservation, onClose }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const webPopupContent = campaign?.content?.website_popup_content;
  const imageUrl = getContentImageUrl(webPopupContent);
  const headerTitle = getHeaderTitle(webPopupContent?.content_type, t);
  const contentTitle = getContentName(webPopupContent);
  const description = getContentDescription(webPopupContent, t);
  const promoCode = webPopupContent?.promo_code;
  const buttonText = getButtonText(webPopupContent, t);
  const buttonUrl = useWebsitePopupButtonUrl({ reservation, campaign });
  const {
    logOnClickCustomerEvent,
    logOnClickMarketingAutomationCampaignEvent,
  } = useWebsitePopupClickLogger({ reservation, campaign });
  const isMapPath = router.pathname.includes('/map') || router.pathname === '/';

  return (
    <section className={styles.offerContainer}>
      <div className={styles.mapAttractions}>
        <div className={styles.overlay}>
          <div className={styles.contentWrapper}>
            <button
              className={styles.closeButton}
              onClick={() => {
                onClose?.();
              }}
            >
              <img
                src="/static/images/map/close_white.svg"
                alt="Close"
                className={styles.closeIcon}
              />
            </button>
            <article className={styles.offerCard}>
              {imageUrl && (
                <div className={styles.imageWrapper}>
                  <img src={imageUrl} alt={contentTitle} className={styles.offerImage} />
                </div>
              )}
              <div className={styles.offerDetails}>
                <div className={styles.offerContent}>
                  <div className={styles.offerText}>
                    <header className={styles.offerHeader}>
                      <p className={styles.limitedTimeOffer}>{headerTitle}</p>
                      <h2 className={styles.offerTitle}>{contentTitle}</h2>
                    </header>

                    {description && <p className={styles.offerDescription}>{description}</p>}
                  </div>

                  {promoCode && <div className={styles.promoCode}>{promoCode}</div>}
                </div>
                <div className={styles.buttonGroup}>
                  {webPopupContent?.message_popup_content?.button_destination_type ===
                    'DIGITAL_MAP_PIN_DETAILS_PAGE' && isMapPath ? (
                    <Link
                      href={buttonUrl ?? ''}
                      shallow
                      onClick={() => {
                        onClose?.();
                        logOnClickCustomerEvent();
                        // Also log MA campaign event
                        logOnClickMarketingAutomationCampaignEvent();
                      }}
                    >
                      <button className={styles.redeemButton}>{buttonText || t('See More')}</button>
                    </Link>
                  ) : (
                    <a
                      href={buttonUrl}
                      onClick={() => {
                        logOnClickCustomerEvent();
                        // Also log MA campaign event
                        logOnClickMarketingAutomationCampaignEvent();
                      }}
                    >
                      <button className={styles.redeemButton}>{buttonText || t('See More')}</button>
                    </a>
                  )}
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
