import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchDisplayedMarketingAutomationCampaigns,
  selectApplicableMarketingAutomationCampaigns,
  selectMarketingAutomationCampaignReservation,
  setActiveMarketingAutomationCampaign,
} from 'ducks/client/marketingAutomationCampaigns';
import { MarketingAutomation } from 'components/MarketingAutomationV2/MarketingAutomationPopup/MarketingAutomation';
import { MarketingAutomationWebsitePopupContent } from 'models/marketingAutomationCampaign';
import { ReduxState } from 'ducks';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { fetchMarketingAutomationCampaign } from 'ducks/client/marketingAutomationCampaigns';
import { removeNotifications } from 'ducks/client/notification';
import { removeWebPushNotification } from 'ducks/client/webPushNotification';
import { useVisitorIdentifier } from 'hooks/useVisitorIdentifier';

interface Props {
  allowedDisplayTypes?: MarketingAutomationWebsitePopupContent['display_type'][];
  changePopupToSlideup?: boolean;
  overwriteDisplayType?: MarketingAutomationWebsitePopupContent['display_type'];
  bottomPosition?: number;
}

export const MarketingAutomationDistributor = (props: Props) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const activeMarketingAutomationCampaign = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.activeMarketingAutomationCampaign
  );
  const marketingAutomationCampaigns = useSelector(selectApplicableMarketingAutomationCampaigns);
  const reservation = useSelector(selectMarketingAutomationCampaignReservation);
  const lastFetchedMarketingAutomationCampaign = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.lastFetchedMarketingAutomationCampaign
  );
  const notifications = useSelector(
    (state: ReduxState) => state.notification.marketingAutomationNotifications
  );
  const webPushnotifications = useSelector(
    (state: ReduxState) => state.webPushNotification.marketingAutomationWebPushNotifications
  );
  const [
    fetchMarketingAutomationCampaignId,
    setFetchMarketingAutomationCampaignId,
  ] = React.useState<string | null>(null);

  const { apiKey } = React.useContext(ApiKeyContext);

  const { trackingId } = useVisitorIdentifier();

  // Fetch displayed marketing automation campaigns when the active campaign changes.
  React.useEffect(() => {
    if (reservation?.id || trackingId) {
      dispatch(
        fetchDisplayedMarketingAutomationCampaigns({
          apiKey,
          reservationId: reservation?.id ?? '',
          trackingId,
          contentLanguage: i18n.language,
        })
      );
    }
  }, [
    apiKey,
    i18n.language,
    activeMarketingAutomationCampaign,
    reservation?.id,
    trackingId,
    dispatch,
  ]);

  React.useEffect(() => {
    if (!notifications || notifications.length === 0) {
      return;
    }
    if (fetchMarketingAutomationCampaignId) {
      return;
    }
    setFetchMarketingAutomationCampaignId(notifications[0].payload.campaign_id);
    dispatch(removeNotifications([notifications[0].id]));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [notifications]);

  React.useEffect(() => {
    if (!webPushnotifications || webPushnotifications.length === 0) {
      return;
    }
    if (fetchMarketingAutomationCampaignId) {
      return;
    }
    setFetchMarketingAutomationCampaignId(webPushnotifications[0].data?.campaign_id ?? '');
    dispatch(removeWebPushNotification(webPushnotifications[0].id ?? ''));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [webPushnotifications]);

  React.useEffect(() => {
    if (fetchMarketingAutomationCampaignId) {
      dispatch(
        fetchMarketingAutomationCampaign(apiKey, fetchMarketingAutomationCampaignId, i18n.language)
      );
      setFetchMarketingAutomationCampaignId(null);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchMarketingAutomationCampaignId]);

  React.useEffect(() => {
    if ((marketingAutomationCampaigns || []).length > 0) {
      dispatch(setActiveMarketingAutomationCampaign(marketingAutomationCampaigns[0]));
    }
  }, [marketingAutomationCampaigns, dispatch]);

  React.useEffect(() => {
    if (lastFetchedMarketingAutomationCampaign) {
      dispatch(setActiveMarketingAutomationCampaign(lastFetchedMarketingAutomationCampaign));
    }
  }, [lastFetchedMarketingAutomationCampaign, dispatch]);

  return (
    <MarketingAutomation
      reservation={reservation}
      campaign={activeMarketingAutomationCampaign}
      onClose={() => {
        dispatch(setActiveMarketingAutomationCampaign(null));
      }}
      {...props}
    />
  );
};
