import * as React from 'react';
import clsx from 'clsx';

import config from 'config';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';

import styles from './MarketingAutomationPopup.module.css';

interface Props {
  campaign: MarketingAutomationCampaign;
  children: (
    ref: React.Ref<HTMLDivElement>,
    props: React.HTMLProps<HTMLDivElement>
  ) => React.ReactNode;
  bottomPosition?: number;
  onClose?: () => void;
}

export const MarketingAutomationVerticalScrollFloatingButton = ({
  campaign,
  children,
  onClose,
}: Props) => {
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !contentRef?.current?.contains(target)) {
        if (expanded) {
          setExpanded(false);
          onClose?.();
        }
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [expanded, onClose]);

  const contentRef = React.useRef<HTMLDivElement>(null);
  const panelStyle = { transform: expanded ? 'translateY(0)' : 'translateY(100%)' };
  const webPopupContent = campaign?.content?.website_popup_content;
  return (
    <>
      {!expanded && (
        <button
          className={clsx(
            styles['recommend__productList__btn'],
            config.enableIAAPADemo && styles['usa']
          )}
          onClick={() => {
            setExpanded(true);
          }}
        >
          <img
            src={webPopupContent?.button_image_url || '/static/images/ic_present.svg'}
            alt="megaphone"
            style={{ width: '40px', height: '40px' }}
          />
        </button>
      )}
      <div style={panelStyle} className={styles['recommend__productList__modal']} id="recommend">
        {children(contentRef, {})}
      </div>
    </>
  );
};
