import * as React from 'react';
import clsx from 'clsx';
import createDecorator from 'final-form-focus';
import { CircularProgress, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';
import { SurveyTemplate } from 'models/surveyTemplate';

import { SurveyQuestionRating } from './SurveyQuestionRating';
import { SurveyQuestionTagSelect } from './SurveyQuestionTagSelect';
import styles from './SurveyForm.module.css';
import { Form } from 'react-final-form';
import { submitSurvey } from 'ducks/client/surveys';
import { SurveyAnswer } from 'models/survey';
import { SurveyQuestionNpsRating } from './SurveyQuestionNpsRating';
import { SurveyQuestionNpsMultiple } from './SurveyQuestionNpsMultiple';
import { SurveyQuestionNpsTextArea } from './SurveyQuestionNpsTextArea';
import { useVisitorIdentifier } from 'hooks/useVisitorIdentifier';

export type FormValues = {
  answers?: Record<string, string[] | string>;
};

const focusOnError = createDecorator<FormValues>();

interface OwnProps {
  onClose: () => void;
  surveyTemplate: SurveyTemplate;
}

type Props = OwnProps;

export const SurveyFormModal = ({ onClose, surveyTemplate }: Props) => {
  const { t } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);
  const [submitted, setSubmitted] = React.useState(false);
  const inFlight = useSelector((state: ReduxState) => state.surveys.inFlight);
  const [npsQuestion2Visible, setNpsQuestion2Visible] = React.useState<boolean>(false);
  const [npsQuestion3Visible, setNpsQuestion3Visible] = React.useState<boolean>(false);
  const [npsQuestion3Validated, setNpsQuestion3Validated] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<boolean>(false);
  const npsQuestion2Ref = React.useRef<HTMLDivElement>(null);
  const npsQuestion3Ref = React.useRef<HTMLDivElement>(null);

  const router = useRouter();
  const reservationId = router.query.r as string;

  const dispatch = useDispatch();
  const visitorIdentifier = useVisitorIdentifier();

  React.useEffect(() => {
    setActive(true);
  }, []);

  const handleValidationResult = (isValid: boolean) => {
    setNpsQuestion3Validated(isValid);
  };

  const handleClose = () => {
    onClose();
    setActive(false);
  };

  return (
    <>
      {submitted ? (
        <div className={clsx(styles['survey__popup__modal'], active ? styles['is-active'] : null)}>
          <div className={styles['survey']}>
            <button
              className={styles['survey__header__new__close__button']}
              onClick={handleClose}
            ></button>
            <div className={styles['survey__inner']}>
              <div className={styles['survey__header']}>
                <div className={styles['survey__header__content']} style={{ width: '70%' }}>
                  {t('Thank you very much for your feedback ')}
                </div>
              </div>
              <div className={styles['new__button']}>
                <button
                  className={clsx(styles['post-main__btn'], styles['new__submit__button'])}
                  onClick={handleClose}
                >
                  <span className={clsx(styles['post-main__btn__txt'])}>{t('Close')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={clsx(styles['survey__popup__modal'], active ? styles['is-active'] : null)}>
          <Form<FormValues>
            onSubmit={async (values: FormValues) => {
              const answers: SurveyAnswer[] = [];
              Object.entries(values.answers ?? {}).forEach(([key, value]) => {
                answers.push({
                  question_key: key,
                  responses: typeof value === 'string' ? [value] : value,
                });
              });

              await dispatch(
                submitSurvey(apiKey, {
                  survey_template_id: surveyTemplate?.id ?? '',
                  survey_template_version_number: surveyTemplate?.version_number ?? 0,
                  reservation_id: reservationId,
                  answers,
                  tracking_id: visitorIdentifier.trackingId,
                })
              );

              setSubmitted(true);
            }}
            initialValues={{}}
            decorators={[focusOnError]}
          >
            {({ handleSubmit }) => {
              const currentPage = surveyTemplate.pages[0];

              return (
                <form onSubmit={handleSubmit}>
                  <>
                    {/* Normal survey */}
                    {!surveyTemplate.is_nps_survey && (
                      <div className={styles['survey']}>
                        <button
                          className={styles['survey__header__new__close__button']}
                          onClick={handleClose}
                        ></button>
                        <div className={styles['survey__inner']}>
                          <div
                            className={styles['survey__header']}
                            style={{ marginBottom: '16px' }}
                          >
                            <div className={styles['survey__header__content']}>
                              {/* TODO: this should be image thumbnail */}
                              {surveyTemplate.title}
                            </div>
                          </div>
                          {currentPage?.questions?.map((question) => {
                            if (question.response_type === 'SURVEY_QUESTION_STAR_RATING') {
                              return (
                                <SurveyQuestionRating key={question.key} question={question} />
                              );
                            }
                            if (question.response_type === 'SURVEY_QUESTION_SELECT_MULTIPLE') {
                              return (
                                <SurveyQuestionTagSelect key={question.key} question={question} />
                              );
                            }
                          })}

                          <div className={styles['buttons']} style={{ marginTop: '16px' }}>
                            <div className={styles['new__button']}>
                              <button
                                disabled={!reservationId}
                                className={clsx(
                                  styles['post-main__btn'],
                                  styles['new__submit__button']
                                )}
                                type="submit"
                              >
                                {inFlight ? (
                                  <Box width="100%" position="absolute">
                                    <Box width="100%" display="flex" justifyContent="center">
                                      <CircularProgress />
                                    </Box>
                                  </Box>
                                ) : (
                                  <span className={clsx(styles['post-main__btn__txt'])}>
                                    {t('Submit')}
                                  </span>
                                )}
                              </button>
                            </div>
                            <div className={styles['new__button']}>
                              <button
                                className={clsx(
                                  styles['post-main__btn'],
                                  styles['new__close__button']
                                )}
                                onClick={handleClose}
                              >
                                <span
                                  className={clsx(
                                    styles['post-main__btn__txt'],
                                    styles['new__close__button__text']
                                  )}
                                >
                                  {t('Close')}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* NPS Survey */}
                    {surveyTemplate.is_nps_survey && (
                      <div className={clsx(styles['survey'], styles['nps__survey'])}>
                        <button
                          className={styles['survey__header__new__close__button']}
                          onClick={handleClose}
                        ></button>
                        <div className={styles['survey__inner']}>
                          <div className={styles['survey__header']}>
                            <div className={styles['survey__header__content']}>
                              {/* TODO: this should be image thumbnail */}
                              {surveyTemplate.title}
                            </div>
                          </div>
                          {surveyTemplate.pages.length > 0 && surveyTemplate.pages[0].title && (
                            <div className={styles['nps__page__header']}>
                              {surveyTemplate.pages[0].title}
                            </div>
                          )}
                          <div className={styles['nps__container']}>
                            {currentPage?.questions?.map((question) => {
                              if (question.response_type === 'SURVEY_QUESTION_STAR_RATING_NPS') {
                                return (
                                  <SurveyQuestionNpsRating
                                    key={question.key}
                                    question={question}
                                    onClick={async () => {
                                      setNpsQuestion2Visible(true);
                                      if (npsQuestion2Ref && npsQuestion2Ref.current) {
                                        if (
                                          !npsQuestion2Ref.current.classList.contains('scrolled')
                                        ) {
                                          npsQuestion2Ref.current.scrollIntoView({
                                            behavior: 'smooth',
                                          });
                                          // Prevent scroll the 2nd time question is answered
                                          npsQuestion2Ref.current.classList.add('scrolled');
                                        }
                                      }
                                    }}
                                  />
                                );
                              }
                              if (
                                npsQuestion2Visible &&
                                question.response_type === 'SURVEY_QUESTION_SELECT_MULTIPLE'
                              ) {
                                return (
                                  <SurveyQuestionNpsMultiple
                                    key={question.key}
                                    question={question}
                                    onClick={async () => {
                                      setNpsQuestion3Visible(true);
                                    }}
                                    ref={npsQuestion2Ref}
                                  />
                                );
                              }
                              if (
                                npsQuestion3Visible &&
                                question.response_type === 'SURVEY_QUESTION_TEXT_AREA'
                              ) {
                                return (
                                  <SurveyQuestionNpsTextArea
                                    key={question.key}
                                    question={question}
                                    onValidate={handleValidationResult}
                                    ref={npsQuestion3Ref}
                                  />
                                );
                              }
                            })}
                            {/* Temporary spacing when question 1 or 2 is answered so question 2 or 3 can be scrolled to top */}
                            {(npsQuestion2Visible || npsQuestion3Visible) && (
                              <div style={{ height: '80px' }}></div>
                            )}
                          </div>
                          {/* Only enable send button if all questions are answered */}
                          <div className={styles['new__button']}>
                            <button
                              disabled={
                                // !reservationId || Note: reservation is not a pre-requisite for NPS survey
                                !npsQuestion2Visible ||
                                !npsQuestion3Visible ||
                                !npsQuestion3Validated
                              }
                              className={clsx(
                                styles['post-main__btn'],
                                styles['new__submit__button']
                              )}
                              type="submit"
                            >
                              {inFlight ? (
                                <Box width="100%" position="absolute">
                                  <Box width="100%" display="flex" justifyContent="center">
                                    <CircularProgress />
                                  </Box>
                                </Box>
                              ) : (
                                <span className={clsx(styles['post-main__btn__txt'])}>
                                  {t('Submit')}
                                </span>
                              )}
                            </button>
                          </div>
                          <div className={styles['new__button']}>
                            <button
                              className={clsx(
                                styles['post-main__btn'],
                                styles['new__close__button']
                              )}
                              onClick={handleClose}
                            >
                              <span
                                className={clsx(
                                  styles['post-main__btn__txt'],
                                  styles['new__close__button__text']
                                )}
                              >
                                {t('Close')}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </form>
              );
            }}
          </Form>
        </div>
      )}
    </>
  );
};
