import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button, Box, CircularProgress } from '@mui/material';

import styles from './ConfirmCouponRedemptionModal.module.css';

import { Wrapper } from 'components/Wrapper/Wrapper';

interface Props {
  title: string;
  submitting: boolean;
  disabled: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmCouponRedemptionModal: React.FC<Props> = ({
  title,
  submitting,
  disabled,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const rootEl = typeof window !== 'undefined' ? document?.getElementById('__next') : undefined;

  return (
    <Wrapper
      wrapper={(children) =>
        rootEl ? ReactDOM.createPortal(children, rootEl as Element) : children
      }
    >
      <div className={styles.modalOverlay}>
        <div className={styles.modalContainer}>
          <section className={styles.alertDialog}>
            <div className={styles.contents}>
              <div className={styles.title}>{title}</div>
              <div className={styles.buttonContainer}>
                <Button
                  onClick={onConfirm}
                  disabled={submitting || disabled}
                  variant="contained"
                  color="primary"
                >
                  {submitting && (
                    <Box width="100%" position="absolute">
                      <Box width="100%" display="flex" justifyContent="center">
                        <CircularProgress size={20} />
                      </Box>
                    </Box>
                  )}
                  {t('Redeem')}
                </Button>
                <Button onClick={onCancel}>{t('Cancel')}</Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

export default ConfirmCouponRedemptionModal;
