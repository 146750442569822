import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import clsx from 'clsx';

import { getImageUrl } from 'lib/util/imageUrl';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import { Reservation } from 'models/reservation';
import {
  MarketingAutomationCampaign,
  MarketingAutomationContentItem,
} from 'models/marketingAutomationCampaign';
import CouponPane from './CouponPane/CouponPane';

import styles from './MarketingAutomationPopup.module.css';
import {
  getAmountAndCurrencyUnit,
  getHeaderTitle,
  getButtonText,
  getMessage,
  getHeaderColor,
} from './utils';
import { useWebsitePopupClickLogger } from './useWebsitePopupClickLogger';
import { useWebsitePopupButtonUrl } from './useWebsitePopupButtonUrl';

interface Props {
  campaign: MarketingAutomationCampaign;
  reservation?: Reservation;
  buttomRadius?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
}

export const MarketingAutomationContentPane = React.forwardRef<HTMLDivElement, Props>(
  ({ campaign, reservation, buttomRadius, hideCloseButton, onClose }, ref) => {
    const { t } = useTranslation();

    const currencyFormatter = useCurrencyFormatter();
    const webPopupContent = campaign?.content?.website_popup_content;

    let contentName = '';
    let contentImageUrl = '';
    let lowestPriceGross = '';
    const buttonText = getButtonText(webPopupContent, t) ?? '';
    let contentDescription = '';
    let product: MarketingAutomationContentItem | null = null;
    let hideButton = false;
    const message = getMessage(webPopupContent?.content_type, t) ?? '';
    const promoCode = webPopupContent?.promo_code;
    if (
      ['ANNUAL_PASS', 'PRODUCT', 'PRODUCT_UPGRADE', 'PRODUCT_LIST'].includes(
        webPopupContent?.content_type
      )
    ) {
      product = webPopupContent?.products?.length ? webPopupContent.products[0] : null;
      contentName = product?.name ?? '';
      contentImageUrl = product?.image_url ?? '';
      lowestPriceGross = product?.lowest_price_gross ?? '';
    } else if (['MESSAGE'].includes(webPopupContent?.content_type)) {
      contentName = webPopupContent?.message_popup_content?.title ?? '';
      contentImageUrl = webPopupContent?.message_popup_content?.image_url ?? '';
      contentDescription = webPopupContent?.message_popup_content?.description ?? '';
      hideButton = webPopupContent?.message_popup_content?.hide_button ?? false;
    } else {
      const coupon = webPopupContent?.coupon;
      contentName = coupon?.name ?? '';
      contentImageUrl = webPopupContent?.thumbnail_url ?? '';
      contentDescription = coupon?.description ?? '';
    }

    const buttonUrl = useWebsitePopupButtonUrl({ reservation: reservation ?? null, campaign });
    const {
      logOnClickCustomerEvent,
      logOnClickMarketingAutomationCampaignEvent,
    } = useWebsitePopupClickLogger({ reservation: reservation ?? null, campaign });

    const formattedAmount = currencyFormatter.format(lowestPriceGross ?? '');
    const { priceAmount, priceUnit } = getAmountAndCurrencyUnit(formattedAmount);

    return (
      <>
        <div className={styles['recommend']}>
          <div
            className={styles['recommend__header']}
            style={{ backgroundColor: getHeaderColor(webPopupContent?.content_type) }}
          >
            <div className={styles['recommend__header__content']}>
              <div className={styles['recommend__header__ttl']}>
                <Image
                  className={styles['recommend__header__ic_present']}
                  height={15}
                  width={15}
                  src="/static/images/ic_present.svg"
                  alt="present"
                  loader={({ src, width }) => getImageUrl(src, width)}
                />
                <span className={styles['recommend__header__ttl__txt']}>
                  {getHeaderTitle(webPopupContent?.content_type, t)}
                </span>
              </div>
              {!hideCloseButton && (
                <Image
                  className={styles['recommend__header__ic_close']}
                  style={{
                    backgroundColor: getHeaderColor(webPopupContent?.content_type),
                  }}
                  height={15}
                  width={15}
                  src="/static/images/ic_close.svg"
                  alt="down allow"
                  loader={({ src, width }) => getImageUrl(src, width)}
                  onClick={onClose ?? undefined}
                />
              )}
            </div>
          </div>
          <div
            className={clsx(styles['recommend__body'], buttomRadius ? styles['inline'] : null)}
            ref={ref}
          >
            <div className={styles['recommend__body__productInfo']} ref={ref}>
              {message && <p className={styles['recommend__body__message']}>{message}🎉</p>}
              {contentImageUrl && (
                <div className={styles['recommend__body__pic__wrapper']}>
                  <img
                    className={styles['recommend__body__pic']}
                    src={contentImageUrl}
                    alt={contentName ?? ''}
                  />
                </div>
              )}

              {contentName && <p className={styles['recommend__body__name']}>{contentName}</p>}

              {priceAmount && (
                <p className={styles['recommend__body__price']}>
                  <span className={styles['recommend__body__price__amount']}>{priceAmount}</span>
                  {priceUnit && (
                    <span className={styles['recommend__body__price__unit']}>{priceUnit}</span>
                  )}
                </p>
              )}

              {contentDescription && (
                <p className={styles['recommend__body__description']}>{contentDescription}</p>
              )}

              {promoCode && (
                <p className={styles['recommend__body__promo_code']}>
                  {t('Promo Code')}:<span>{promoCode}</span>
                </p>
              )}
            </div>

            {!hideButton && (
              <>
                {webPopupContent?.content_type === 'COUPON' && (
                  <CouponPane
                    campaign={campaign}
                    reservationId={reservation?.id ?? ''}
                    buttonText={buttonText}
                    onClick={() => {
                      logOnClickCustomerEvent();
                      // Also log MA campaign event
                      logOnClickMarketingAutomationCampaignEvent();
                    }}
                  />
                )}
                {webPopupContent?.content_type !== 'COUPON' && (
                  <div className={styles['recommend__body__footer']}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className={styles['recommend__body__btn']}
                      href={buttonUrl}
                      onClick={() => {
                        logOnClickCustomerEvent();
                        // Also log MA campaign event
                        logOnClickMarketingAutomationCampaignEvent();
                      }}
                    >
                      <span className={styles['recommend__body__btn__txt']}>
                        {buttonText || t('MORE')}
                      </span>
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);
