import * as React from 'react';
import config from 'config';
import axios from 'axios';
import moment from 'moment-timezone';
import { useState } from 'react';
import { Reservation } from 'models/reservation';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { useUrl } from 'hooks/useUrl';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { getGuestCount } from 'lib/util/getGuestCount';

export const useWebsitePopupChangeReservationUrl = ({
  reservation,
  campaign,
}: {
  reservation: Reservation | null;
  campaign: MarketingAutomationCampaign;
}) => {
  const [changeReservationUrl, setChangeReservationUrl] = useState('');
  const webPopupContent = campaign?.content?.website_popup_content;
  const product = webPopupContent?.products?.length ? webPopupContent.products[0] : null;

  const { apiKey } = React.useContext(ApiKeyContext);
  const changeReservationBaseUrl = useUrl(`/products/${product?.id ?? ''}/change`);

  React.useEffect(() => {
    if (!product) {
      return;
    }
    if (!webPopupContent?.content_type) {
      return;
    }
    if (!apiKey) {
      return;
    }
    if (!changeReservationBaseUrl) {
      return;
    }
    if (!reservation) {
      return;
    }
    const url = `${config.apiUrl}/products/${product.id}/instances/`;
    const startDate = moment.tz(reservation.start_date_time_utc, reservation.start_timezone);
    const endDate = startDate.clone().add(1, 'days');
    axios
      .get(url, {
        params: {
          start_date_local_from: startDate.format('YYYY-MM-DD'),
          start_date_local_to: endDate.format('YYYY-MM-DD'),
        },
        headers: { 'x-api-key': apiKey },
      })
      .then((response) => {
        if (response?.data?.instances?.length > 0) {
          const targetInstance = response.data.instances.reverse()[0];
          const guestCount = getGuestCount(reservation);

          const searchParams = new URLSearchParams();
          searchParams.append('r', reservation.id);
          searchParams.append('productInstanceId', targetInstance.id);
          searchParams.append('ts', reservation.last_updated_date_time_utc);
          searchParams.append('guestCount', JSON.stringify(guestCount));
          searchParams.append('utm_campaign', campaign.id);

          // TODO: subject to change, for now need to match param used in banda stat calculation for bookings count
          if (campaign.content.type === 'WEBSITE_POPUP') {
            searchParams.append('utm_source', 'ntmg.newsletter');
          }

          let url = '';
          if (changeReservationBaseUrl.includes('?')) {
            url = `${changeReservationBaseUrl}&${searchParams.toString()}`;
          } else {
            url = `${changeReservationBaseUrl}?${searchParams.toString()}`;
          }
          setChangeReservationUrl(url);
        }
      });
  }, [
    apiKey,
    campaign,
    changeReservationBaseUrl,
    product,
    reservation,
    webPopupContent?.content_type,
  ]);

  return changeReservationUrl;
};
