import { WithTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { SalesOffer, SalesOfferConditions } from 'models/salesOffer';
import { Reservation } from 'models/reservation';
import { MarketingAutomationWebsitePopupContent } from 'models/marketingAutomationCampaign';

export const findConditionRule = (
  offer: SalesOffer,
  reservation: Reservation
): SalesOfferConditions | null => {
  if (!offer?.condition_rules) {
    return null;
  }

  const currentDate = moment.tz(reservation?.start_timezone ?? 'UTC').format('YYYY-MM-DD');
  const currentTimeOfDay = moment.tz(reservation?.start_timezone ?? 'UTC').format('HH:mm:ss');

  const conditionRule = offer.condition_rules.find((item) => {
    if (
      item.booked_product_ids?.length > 0 &&
      !item.booked_product_ids?.includes(reservation?.product_id ?? '')
    ) {
      return false;
    }
    if (item.participation_start_date && currentDate < item.participation_start_date) {
      return false;
    }
    if (item.participation_end_date && currentDate > item.participation_end_date) {
      return false;
    }
    if (item.time_of_day_start && currentTimeOfDay < item.time_of_day_start) {
      return false;
    }
    if (item.time_of_day_end && currentTimeOfDay > item.time_of_day_end) {
      return false;
    }
    return true;
  });

  return conditionRule ?? null;
};

export const getAmountAndCurrencyUnit = (
  formattedAmount: string
): {
  priceAmount: string;
  priceUnit: string;
} => {
  let priceUnit = '';
  let priceAmount = '';
  if (formattedAmount.endsWith('円')) {
    priceUnit = '円';
    priceAmount = formattedAmount.slice(0, -1);
  } else if (/^[A-Z]{3}.*$/.test(formattedAmount)) {
    priceUnit = formattedAmount.slice(0, 3);
    priceAmount = formattedAmount.slice(3);
  } else {
    priceAmount = formattedAmount ?? '';
  }

  return { priceAmount: priceAmount, priceUnit: priceUnit };
};

export const getHeaderTitle = (contentType: string, t: WithTranslation['t']): string => {
  switch (contentType) {
    case 'PRODUCT':
      return t('Recommended Products');
    case 'PRODUCT_UPGRADE':
      return t('Discounted Upgrades');
    case 'COUPON':
      return t('Coupons');
    case 'ANNUAL_PASS':
      return t('Discounted Annual Pass');
    case 'MESSAGE':
      return t('Recommendation');
    case 'PRODUCT_LIST':
      return t('Recommended Product List');
    case 'SURVEY':
      return t('How was it?', { context: 'marketing-automation' });
    case 'POPULAR_PRODUCT_LIST':
      return t('Popular Product Rankings');
    default:
      return t('Exclusive offer');
  }
};

export const getHeaderColor = (contentType: string): string => {
  switch (contentType) {
    case 'COUPON':
      return '#4DA1EF';
    default:
      return '#F43F5E';
  }
};

export const getButtonText = (
  webPopupContent: MarketingAutomationWebsitePopupContent,
  t: WithTranslation['t']
): string => {
  switch (webPopupContent?.content_type) {
    case 'ANNUAL_PASS':
    case 'PRODUCT_UPGRADE':
      return t('Proceed to purchase');
    case 'PRODUCT':
      return t('See more');
    case 'MESSAGE':
      return webPopupContent?.message_popup_content?.button_text ?? '';

    case 'COUPON':
      return t('Use coupon');
    case 'SURVEY':
      return t('Answer');
    default:
      return '';
  }
};

export const getMessage = (contentType: string, t: WithTranslation['t']): string => {
  switch (contentType) {
    case 'PRODUCT':
      return t('People who checked this product also viewed these items!');
    case 'PRODUCT_UPGRADE':
      return t(
        'You can purchase at a discount by paying only the difference from the regular ticket!'
      );
    case 'ANNUAL_PASS':
      return t(
        'You can purchase at a discount by paying only the difference from the regular ticket!'
      );
    case 'SURVEY':
      return t('Thank you for your cooperation with the survey!');
    default:
      return '';
  }
};

export const getContentName = (webPopupContent: MarketingAutomationWebsitePopupContent) => {
  if (
    ['ANNUAL_PASS', 'PRODUCT', 'PRODUCT_UPGRADE', 'PRODUCT_LIST'].includes(
      webPopupContent?.content_type
    )
  ) {
    return webPopupContent?.products?.length ? webPopupContent.products[0].name : '';
  } else if (['MESSAGE'].includes(webPopupContent?.content_type)) {
    return webPopupContent?.message_popup_content?.title ?? '';
  } else {
    return webPopupContent?.coupon?.name ?? '';
  }
};

export const getContentImageUrl = (webPopupContent: MarketingAutomationWebsitePopupContent) => {
  if (
    ['ANNUAL_PASS', 'PRODUCT', 'PRODUCT_UPGRADE', 'PRODUCT_LIST'].includes(
      webPopupContent?.content_type
    )
  ) {
    return webPopupContent?.products?.length ? webPopupContent.products[0].image_url : '';
  } else if (['MESSAGE'].includes(webPopupContent?.content_type)) {
    return webPopupContent?.message_popup_content?.image_url ?? '';
  } else {
    return webPopupContent?.thumbnail_url ?? '';
  }
};

export const getContentDescription = (
  webPopupContent: MarketingAutomationWebsitePopupContent,
  t: WithTranslation['t']
) => {
  if (['MESSAGE'].includes(webPopupContent?.content_type)) {
    return webPopupContent?.message_popup_content?.description ?? '';
  } else if (webPopupContent?.content_type === 'PRODUCT' && webPopupContent?.promo_code) {
    return t('Use the following promo code at checkout to receive a discount!');
  } else {
    return webPopupContent?.coupon?.description ?? '';
  }
};
