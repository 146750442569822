import * as React from 'react';
import Image from 'next/legacy/image';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './MarketingAutomationPopup.module.css';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { CustomerContext } from 'contexts/CustomerContext';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { ReduxState } from 'ducks';
import { Reservation } from 'models/reservation';
import { getImageUrl } from 'lib/util/imageUrl';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';

interface Props {
  campaign: MarketingAutomationCampaign;
  reservation: Reservation | null;
  bottomPosition?: number;
}

export const MarketingAutomationWebPushNotificationPopup = ({ campaign, reservation }: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();

  const pushNotificationContent = campaign?.content?.push_notification_content;
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const { apiKey } = React.useContext(ApiKeyContext);
  const { currentPosition } = React.useContext(CurrentPositionContext);
  const { customer } = React.useContext(CustomerContext);

  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !contentRef?.current?.contains(target)) {
        setExpanded(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, []);

  React.useEffect(() => {
    if (campaign) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [campaign, reservation]);

  return (
    <div
      style={{ transform: expanded ? 'translateY(0)' : 'translateY(100%)' }}
      className={styles['recommend__popup__modal']}
      id="recommend"
    >
      <div className={styles['recommend']}>
        <div className={styles['recommend__header']}>
          <div className={styles['recommend__header__content']}>
            <div className={styles['recommend__header__ttl']}>
              <Image
                className={styles['recommend__header__ic_present']}
                height={15}
                width={15}
                src="/static/images/ic_present.svg"
                alt="present"
                loader={({ src, width }) => getImageUrl(src, width)}
              />
              <span className={styles['recommend__header__ttl__txt']}>{t('Recommendation')}</span>
            </div>
            <Image
              className={styles['recommend__header__ic_close']}
              height={15}
              width={15}
              src="/static/images/ic_down_arrow.svg"
              alt="down allow"
              loader={({ src, width }) => getImageUrl(src, width)}
            />
          </div>
        </div>
        <div className={clsx(styles['recommend__body'], styles['inline'])} ref={contentRef}>
          <div className={styles['recommend__body__productInfo']} ref={contentRef}>
            <p className={styles['recommend__body__message']}>{t('Special offer for you')}🎉</p>
            {pushNotificationContent?.image_url && (
              <div className={styles['recommend__body__pic__wrapper']}>
                <img
                  className={styles['recommend__body__pic']}
                  src={pushNotificationContent?.image_url}
                  alt={pushNotificationContent?.title ?? ''}
                />
              </div>
            )}

            {pushNotificationContent?.title && (
              <p className={styles['recommend__body__name']}>{pushNotificationContent.title}</p>
            )}

            {pushNotificationContent?.body && (
              <p className={styles['recommend__body__description']}>
                {pushNotificationContent.body}
              </p>
            )}
          </div>

          {pushNotificationContent?.link_url && (
            <div className={styles['recommend__body__footer']}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles['recommend__body__btn']}
                href={pushNotificationContent?.link_url}
                onClick={() => {
                  logCustomerEventWithNoSideEffects({
                    apiKey,
                    eventType: 'push-notification-click',
                    visitorToken,
                    reservationId: reservation?.id ?? '',
                    metadata: JSON.stringify({
                      lat: currentPosition?.lat(),
                      lng: currentPosition?.lng(),
                      site: 'onsite',
                      marketing_automation_campaign_id: campaign.id,
                    }),
                    customerId: customer?.id ?? '',
                  });
                }}
              >
                <span className={styles['recommend__body__btn__txt']}>{t('MORE')}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
