import * as React from 'react';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { Reservation } from 'models/reservation';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { CustomerContext } from 'contexts/CustomerContext';
import { logMarketingAutomationCampaignEventWithNoSideEffects } from 'ducks/client/marketingAutomationCampaignEvents';

export const useWebsitePopupClickLogger = ({
  campaign,
  reservation,
}: {
  campaign: MarketingAutomationCampaign;
  reservation: Reservation | null;
}) => {
  const { apiKey } = React.useContext(ApiKeyContext);
  const { currentPositionRef } = React.useContext(CurrentPositionContext);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const { customer } = React.useContext(CustomerContext);

  const logOnClickCustomerEvent = () => {
    logCustomerEventWithNoSideEffects({
      apiKey,
      eventType:
        campaign.content.type === 'PUSH_NOTIFICATION'
          ? 'push-notification-click'
          : 'website-popup-click',
      visitorToken,
      reservationId: reservation?.id ?? '',
      metadata: JSON.stringify({
        lat: currentPositionRef.current?.lat(),
        lng: currentPositionRef.current?.lng(),
        site: 'onsite',
        marketing_automation_campaign_id: campaign.id,
      }),
      customerId: customer?.id ?? '',
    });
  };

  const logOnClickMarketingAutomationCampaignEvent = () => {
    logMarketingAutomationCampaignEventWithNoSideEffects({
      apiKey,
      campaignId: campaign.id,
      eventType: 'CLICK',
      reservationId: reservation?.id ?? '',
      customerId: customer?.id ?? '',
    });
  };

  return {
    logOnClickCustomerEvent,
    logOnClickMarketingAutomationCampaignEvent,
  };
};
