import * as React from 'react';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { Reservation } from 'models/reservation';
import { useWebsitePopupChangeReservationUrl } from './useWebsitePopupChangeReservationUrl';
import { appendQueryString } from 'lib/util/appendQueryString';

export const useWebsitePopupButtonUrl = ({
  reservation,
  campaign,
}: {
  reservation: Reservation | null;
  campaign: MarketingAutomationCampaign;
}) => {
  const { useApiKeyInPaths, apiKey } = React.useContext(ApiKeyContext);
  const getPageUrl = (pageName: string) => {
    return appendQueryString(
      `${useApiKeyInPaths ? `/${apiKey}` : ''}${pageName}`,
      'contentType=website_popup'
    );
  };
  const webPopupContent = campaign?.content?.website_popup_content;
  const product = webPopupContent?.products?.length ? webPopupContent.products[0] : null;

  const changeReservationUrl = useWebsitePopupChangeReservationUrl({ reservation, campaign });

  if (webPopupContent?.content_type === 'MESSAGE') {
    switch (webPopupContent?.message_popup_content?.button_destination_type) {
      case 'PRODUCT_PAGE':
      case 'CUSTOM_PAGE':
      case 'DIGITAL_MAP_PIN_DETAILS_PAGE':
        return getPageUrl(
          webPopupContent?.message_popup_content?.button_destination_page_path ?? ''
        );
      case 'FULL_URL':
      default:
        return webPopupContent?.message_popup_content?.button_destination_url ?? '';
    }
  }
  if (webPopupContent?.content_type === 'PRODUCT') {
    return getPageUrl(`/products/${product?.id}?r=${reservation?.id ?? ''}`);
  }
  if (
    webPopupContent?.content_type === 'ANNUAL_PASS' ||
    webPopupContent?.content_type === 'PRODUCT_UPGRADE'
  ) {
    return changeReservationUrl;
  }
  return undefined;
};
