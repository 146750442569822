import * as React from 'react';
import clsx from 'clsx';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SurveyQuestion } from 'models/surveyTemplate';
import { composeValidators } from 'lib/util/composeValidators';
import { getOptionalValidator, required, Validator } from 'lib/forms/validate';
import styles from './SurveyForm.module.css';
import { FormValues } from './SurveyFormModal';

interface Props {
  question: SurveyQuestion;
  onValidate: (isValid: boolean) => void;
  ref: React.Ref<HTMLDivElement>;
}

export const SurveyQuestionNpsTextArea = React.forwardRef<HTMLDivElement, Props>(
  ({ question, onValidate }, ref) => {
    const { t } = useTranslation();
    const { values } = useFormState<FormValues>();
    const textAreaAnswer = values.answers?.[question.key];

    React.useEffect(() => {
      if (!question.required) {
        onValidate(true);
        return;
      }
      if (textAreaAnswer) {
        onValidate(true);
        return;
      } else {
        onValidate(false);
      }
    }, [question.required, onValidate, textAreaAnswer]);

    const validators: Validator[] = [];
    if (question.required) {
      validators.push(required);
    }
    if (question.format) {
      validators.push(getOptionalValidator(question.format));
    }
    const validate = validators.length > 0 ? composeValidators(...validators) : undefined;

    return (
      <div ref={ref} className={styles['post-form__box']} style={{ padding: '8px' }}>
        <div className={styles['post-form__box__ttl']}>
          <span>
            {question.question_text} {question.required && '*'}
          </span>
        </div>
        <div className={styles['post-form__box__body']} style={{ marginTop: '12px' }}>
          <div className={clsx(styles['post-form__box__body__checkbox'], styles['checkbox'])}>
            <Field<string> name={`answers.${question.key}`}>
              {({ input }) => (
                <textarea className={styles['post-form__box__body__textarea']} {...input} />
              )}
            </Field>
          </div>
          {validate && (
            <Field<string> name={`answers.${question.key}`} validate={validate}>
              {(props) => {
                const isError = props.meta.touched && props.meta.error;
                return isError ? (
                  <p className={styles['post-form__err']}>{t(props.meta.error)}</p>
                ) : null;
              }}
            </Field>
          )}
        </div>
      </div>
    );
  }
);
