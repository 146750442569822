import { Reservation } from 'models/reservation';
import { Attribution, ReviewRating } from 'models/review';
import { WithTranslation } from 'react-i18next';

export interface ReviewFormValues {
  nickname: string;
  rating: ReviewRating | null;
  attribution: Attribution | null;
  title: string;
  body: string;
  imageUrls: string[];
}

export const getInitialValues = (
  reservation: Reservation | null,
  ratingQueryParam: string
): ReviewFormValues => {
  return {
    nickname:
      reservation?.field_responses.find((response) => response.key === 'given_name')?.response ??
      '',
    attribution: null,
    rating: convertQueryParamToReviewRating(ratingQueryParam),
    title: '',
    body: '',
    imageUrls: [],
  };
};

export interface ReviewRatingDisplayProps {
  colorImg: string;
  grayImg: string;
  text: string;
  value: ReviewRating;
  stars: 1 | 2 | 3 | 4 | 5;
}

export const getReviewRatingDisplayProps = (
  rating: ReviewRating,
  t: WithTranslation['t']
): ReviewRatingDisplayProps => {
  switch (rating) {
    case 'REVIEW_RATING_1':
      return {
        colorImg: '/static/images/rating/ic_review_1_color.svg',
        grayImg: '/static/images/rating/ic_review_1_gray.svg',
        text: t('Poor'),
        value: 'REVIEW_RATING_1',
        stars: 1,
      };
    case 'REVIEW_RATING_2':
      return {
        colorImg: '/static/images/rating/ic_review_2_color.svg',
        grayImg: '/static/images/rating/ic_review_2_gray.svg',
        text: t('Somewhat Poor'),
        value: 'REVIEW_RATING_2',
        stars: 2,
      };
    case 'REVIEW_RATING_3':
      return {
        colorImg: '/static/images/rating/ic_review_3_color.svg',
        grayImg: '/static/images/rating/ic_review_3_gray.svg',
        text: t('As Expected'),
        value: 'REVIEW_RATING_3',
        stars: 3,
      };
    case 'REVIEW_RATING_4':
      return {
        colorImg: '/static/images/rating/ic_review_4_color.svg',
        grayImg: '/static/images/rating/ic_review_4_gray.svg',
        text: t('Very good'),
        value: 'REVIEW_RATING_4',
        stars: 4,
      };
    case 'REVIEW_RATING_5':
      return {
        colorImg: '/static/images/rating/ic_review_5_color.svg',
        grayImg: '/static/images/rating/ic_review_5_gray.svg',
        text: t('Excellent!'),
        value: 'REVIEW_RATING_5',
        stars: 5,
      };
  }
};

export const getAttributionText = (attribution: Attribution, t: WithTranslation['t']): string => {
  switch (attribution) {
    case 'FAMILY':
      return t('Family');
    case 'COUPLE':
      return t('Couple');
    case 'FRIEND':
      return t('Friends / Colleagues');
    case 'SOLO':
      return t('Solo');
    case 'BUSINESS':
      return t('Work');
  }
};

const convertQueryParamToReviewRating = (queryParam: string): ReviewRating | null => {
  switch (queryParam) {
    case '1':
      return 'REVIEW_RATING_1';
    case '2':
      return 'REVIEW_RATING_2';
    case '3':
      return 'REVIEW_RATING_3';
    case '4':
      return 'REVIEW_RATING_4';
    case '5':
      return 'REVIEW_RATING_5';
    default:
      return null;
  }
};
