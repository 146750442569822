import { ReduxState } from 'ducks';
import { loadNonReservationToken } from 'ducks/client/tracking';
import _ from 'lodash';
import { Reservation } from 'models/reservation';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

export type VisitorIdentifier = {
  reservationId: string;
  customerId: string;
  trackingId: string;
};

/**
 * Retrieves integrated identifier for visitor for reservation or non-reservation.
 * @returns {VisitorIdentifier} An object containing reservationId, customerId, and trackingId.
 */
export const useVisitorIdentifier = (): VisitorIdentifier => {
  const customerReservations = useSelector(
    (state: ReduxState) => state.reservation.foundReservations
  );
  let customerReservation: Reservation | null;
  if (customerReservations.length > 0) {
    customerReservation = _.orderBy(customerReservations, ['booked_date_time_utc'], 'asc')[0];
  }
  const reservation = useSelector(
    (state: ReduxState) =>
      state.reservation.lastCreatedReservation ||
      state.server.reservation.reservation ||
      customerReservation
  );
  const router = useRouter();
  const reservationIdFromQueryParam = router.query?.r as string;
  const customer = useSelector((state: ReduxState) => state.customer.customer);
  const trackingId = loadNonReservationToken() ?? '';

  return {
    // Prioritize reservation id from query param
    reservationId: reservationIdFromQueryParam || reservation?.id || '',
    customerId: customer?.id || '',
    trackingId,
  };
};
