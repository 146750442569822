import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectPopularProductSummaries } from 'lib/util/privateMarketplace';

import { MarketingAutomationVerticalScrollProductItem } from './MarketingAutomationVerticalScrollProductItem';

import styles from './MarketingAutomationPopup.module.css';

export const MarketingAutomationVerticalScrollPopularProduct = () => {
  const products = useSelector(selectPopularProductSummaries);

  return (
    <div className={styles['verticalScroll__body__inner']}>
      <div className={styles['verticalScroll__body__inner__scroll']}>
        {products.map((product, index) => (
          <MarketingAutomationVerticalScrollProductItem
            key={product.id}
            product={product}
            ranking={index + 1}
          />
        ))}
      </div>
    </div>
  );
};
