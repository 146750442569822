import * as React from 'react';

import styles from './MarketingAutomationPopup.module.css';
import { MarketingAutomationContentPane } from './MarketingAutomationContentPane';
import { Reservation } from 'models/reservation';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';

interface Props {
  campaign: MarketingAutomationCampaign;
  reservation?: Reservation;
  bottomPosition?: number;
}

export const MarketingAutomationPopup = ({ campaign, reservation }: Props) => {
  const [expanded, setExpanded] = React.useState(false);

  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !contentRef?.current?.contains(target)) {
        setExpanded(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, []);

  React.useEffect(() => {
    if (campaign) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [campaign, reservation]);

  return (
    <div
      style={{ transform: expanded ? 'translateY(0)' : 'translateY(100%)' }}
      className={styles['recommend__popup__modal']}
      id="recommend"
    >
      <MarketingAutomationContentPane
        campaign={campaign}
        reservation={reservation}
        ref={contentRef}
        buttomRadius={true}
      />
    </div>
  );
};
