import * as React from 'react';

import styles from './MarketingAutomationPopup.module.css';
import { MarketingAutomationContentPane } from './MarketingAutomationContentPane';
import { Reservation } from 'models/reservation';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';

interface Props {
  campaign: MarketingAutomationCampaign;
  reservation?: Reservation;
}

export const MarketingAutomationInline = ({ campaign, reservation }: Props) => {
  return (
    <div className={styles['recommend__inline']}>
      <MarketingAutomationContentPane
        campaign={campaign}
        reservation={reservation}
        buttomRadius={true}
        hideCloseButton={true}
      />
    </div>
  );
};
