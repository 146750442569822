import config from 'config';

export const logMarketingAutomationCampaignEventWithNoSideEffects = ({
  apiKey,
  campaignId,
  eventType,
  reservationId,
  trackingId,
  customerId,
}: {
  apiKey: string;
  campaignId: string;
  eventType: 'IMPRESSION' | 'CLICK';
  reservationId?: string;
  trackingId?: string;
  customerId?: string;
}): Promise<void> => {
  return fetch(`${config.apiUrl}/marketingautomationcampaignevents`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    keepalive: true,
    body: JSON.stringify({
      campaign_id: campaignId,
      event_type: eventType,
      customer_id: customerId,
      reservation_id: reservationId,
      tracking_id: trackingId,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to log marketing automation campaign event');
    }
  });
};
