import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { ReduxState } from 'ducks';
import { SurveyFormModal } from 'components/MarketingAutomationV2/SurveyFrom/SurveyFormModal';
import { fetchSurveyTemplate } from 'ducks/client/surveys';

interface Props {
  campaign: MarketingAutomationCampaign;
}

export const MarketingAutomationSurvey = ({ campaign }: Props) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);
  const surveyTemplate = useSelector((state: ReduxState) => state.surveys.surveyTemplate);
  const [openSurveyFormModal, setOpenSurveyFormModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!campaign?.content?.website_popup_content?.survey_template_id) {
      return;
    }
    dispatch(
      fetchSurveyTemplate(
        apiKey,
        campaign.content.website_popup_content?.survey_template_id,
        i18n.language
      )
    );
  }, [apiKey, campaign, i18n.language, dispatch]);

  React.useEffect(() => {
    if (surveyTemplate) {
      setOpenSurveyFormModal(true);
    }
  }, [surveyTemplate]);

  if (!surveyTemplate || !openSurveyFormModal) {
    return null;
  }

  return (
    <SurveyFormModal
      surveyTemplate={surveyTemplate}
      onClose={() => {
        setOpenSurveyFormModal(false);
      }}
    />
  );
};
