import * as React from 'react';

import Image from 'next/legacy/image';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductSummaryShape } from 'lib/util/productSummaryShape';
import { getImageUrl } from 'lib/util/imageUrl';
import { getWeekdayText } from 'lib/util/getWeekdayText';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

import styles from './MarketingAutomationPopup.module.css';
import { getAmountAndCurrencyUnit } from './utils';

export interface Props {
  product: ProductSummaryShape;
  ranking: number;
}
export const MarketingAutomationHorizontalScrollProductItem = ({ product, ranking }: Props) => {
  const { t } = useTranslation();
  const currencyFormatter = useCurrencyFormatter();

  const formattedAmount = currencyFormatter.format(product.lowPrice ?? '');
  const { priceAmount, priceUnit } = getAmountAndCurrencyUnit(formattedAmount);

  const { useApiKeyInPaths, apiKey } = React.useContext(ApiKeyContext);

  const getPageUrl = (pageName: string) => {
    return `${useApiKeyInPaths ? `/${apiKey}` : ''}${pageName}`;
  };
  const handleProductClick = () => {
    const url = getPageUrl(`/products/${product?.id}`);
    window.open(url, '_blank');
  };

  return (
    <div className={styles['horizontalScroll__card']} onClick={() => handleProductClick()}>
      <div className={styles['horizontalScroll__card__pic']}>
        {product.imageURL && (
          <Image
            layout="fill"
            objectFit="contain"
            src={product.imageURL}
            loader={({ src, width }) => getImageUrl(src, width)}
            alt="product-thumbnail"
          />
        )}
        <div className={styles['horizontalScroll__card__pic__labelframe']}>
          {product.shouldShowOnlyForLoggedInCustomer && (
            <div className={styles['horizontalScroll__card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Members Only')}</p>
            </div>
          )}
          <div className={styles['horizontalScroll__card__pic__labelframe__rankingnumber']}>
            <div>
              <img src="/static/images/ic_ranking_white.svg" alt="ranking" />
            </div>
            <p>{ranking}</p>
          </div>
        </div>
      </div>
      <div className={styles['horizontalScroll__card__productInfo']}>
        <div className={clsx(styles['horizontalScroll__card__ttl'], 'maincolor-txt')}>
          {product.name}
        </div>
        <div className={clsx(styles['horizontalScroll__card__price'], 'maincolor-txt')}>
          <span className={styles['amount']}>{priceAmount}</span>
          {priceUnit && <span className={styles['unit']}>{priceUnit}</span>}
        </div>
        <div className={styles['horizontalScroll__card__week']}>
          {t('Days of week: {{operatingDaysText}}', {
            operatingDaysText: product.operatingWeekdays
              .map((weekday) => getWeekdayText(weekday, t))
              .join(','),
          })}
        </div>
        <div className={styles['horizontalScroll__card__header__star']}>
          {product.reviewCount != null && product.reviewCount != 0 && (
            <div className={styles['horizontalScroll__card__header__star']}>
              <img src="/static/images/ic_star_on.svg" alt="star" />
              <span>{(product.reviewRating ?? 0).toFixed(1)}</span>
              <span>({product.reviewCount})</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
